<template>
  <a-form
    ref="formRef5"
    :model="formState"
    :label-col="labelCol"
    :rules="rules"
    :wrapper-col="wrapperCol"
  >
    <a-form-item label="发票类型" class="">
      <a-select
        ref="select"
        placeholder="选择开票类型"
        v-model:value="formState.invoice_type"
        style="width: 200px"
        @change="selectModify"
      >
        <a-select-option value="增值税专用发票">增值税专用发票</a-select-option>
        <a-select-option value="增值税普通发票">增值税普通发票</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="开票信息" class="label colon"> </a-form-item>
    <a-form-item label="开票金额">
      <a-input v-model:value="formState.costs" disabled />
    </a-form-item>
    <a-form-item ref="header" label="发票抬头" name="header">
      <a-input v-model:value="formState.header" />
    </a-form-item>
    <a-form-item label="统一社会信用代码（税号）" name="credit_code">
      <a-input v-model:value="formState.credit_code" />
    </a-form-item>
    <a-form-item label="开户银行名称" name="bank_name">
      <a-input v-model:value="formState.bank_name" />
    </a-form-item>
    <a-form-item label="基本开户账号" name="account_number">
      <a-input v-model:value="formState.account_number" />
    </a-form-item>
    <a-form-item label="注册所地址" name="registered_place">
      <a-input v-model:value="formState.registered_place" />
    </a-form-item>
    <a-form-item label="注册固定电话" name="landline">
      <a-input v-model:value="formState.landline" />
    </a-form-item>
    <a-form-item label="缴费订单盖公章" class="" name="pay_array">
      <my-upload :obj="{ id:'pay_array', fileList: imgObj.pay_array, itemArrFun, size:3, name:'缴费订单', url:'uploadInvoice', type: 'invoice', fileSize: 10, accept: '.jpg,.jpeg,.png,.dds,.psd,.pdt,.gif,.svg,.pdf' }" />
    </a-form-item>
    <a-form-item label="营业执照" class="" name="license">
      <my-upload :obj="{ id:'license', fileList: imgObj.license, itemArrFun, size:1, name:'营业执照', url:'uploadInvoice', type: 'invoice', fileSize: 10, accept: '.jpg,.jpeg,.png,.dds,.psd,.pdt,.gif,.svg,.pdf' }" />
    </a-form-item>
    <a-form-item
      label="增值税一般纳税人证明"
      class=""
      v-if="formState.invoice_type === '增值税专用发票'"
      name="prove"
    >
      <my-upload :obj="{ id:'prove', fileList: imgObj.prove, itemArrFun, size:1, name:'纳税人证明', url:'uploadInvoice', type: 'invoice', fileSize: 10, accept: '.jpg,.jpeg,.png,.dds,.psd,.pdt,.gif,.svg,.pdf' }" />
    </a-form-item>
    <a-form-item label="开票信息" class="" name="invoicing">
      <my-upload :obj="{ id:'invoicing', fileList: imgObj.invoicing, itemArrFun, size:1, name:'开票信息', url:'uploadInvoice', type: 'invoice', fileSize: 10, accept: '.jpg,.jpeg,.png,.dds,.psd,.pdt,.gif,.svg,.pdf' }" />
    </a-form-item>
    <a-form-item label="邮递发票信息" class="label colon"> </a-form-item>
    <a-form-item label="发票收件邮箱" name="email">
      <a-input v-model:value="formState.email" placeholder="" />
    </a-form-item>
    <!-- <a-form-item label="发票收件人" name="invoice_recipient">
      <a-input v-model:value="formState.invoice_recipient" placeholder="" />
    </a-form-item>
    <a-form-item label="发票收件电话" name="telephone_recipient">
      <a-input v-model:value="formState.telephone_recipient" />
    </a-form-item>
    <a-form-item label="发票邮递地址" name="address_recipient">
      <a-textarea v-model:value="formState.address_recipient" :rows="4" />
    </a-form-item> -->
    <a-form-item :wrapper-col="{ span: 8, offset: 8 }">
      <a-button type="primary" @click="onSubmit" block>提交</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import {
  defineComponent,
  onMounted,
  reactive,
  toRaw,
  getCurrentInstance,
  ref
} from 'vue'
import MyUpload from '../../components/smallParts/MyUpload.vue'
import { operationInvoice, findInvoice } from '../../api'
import { message, notification } from 'ant-design-vue'
export default defineComponent({
  components: { MyUpload },
  setup() {
    const { proxy } = getCurrentInstance()
    const formRef5 = ref()
    const formState = reactive({
      costs: 0,
      invoice_type: '增值税普通发票',
      header: '',
      credit_code: '',
      bank_name: '',
      account_number: '',
      registered_place: '',
      landline: '',
      pay_array: '',
      license: '',
      prove: '',
      invoicing: '',
      // invoice_recipient: '',
      // telephone_recipient: '',
      // address_recipient: ''
      email: ''
    })
    const imgObj = reactive({
      pay_array: [],
      license: [],
      prove: [],
      invoicing: []
    })
    let rules = ref({
      header: [
        {
          required: true,
          message: '请填写抬头',
          trigger: 'blur'
        }
      ],
      credit_code: [
        {
          required: true,
          message: '请填写税号',
          trigger: 'blur'
        }
      ],

      prove: [
        {
          required: true,
          message: '增值税一般纳税人证明',
          trigger: 'blur'
        }
      ],
      invoicing: [
        {
          required: true,
          message: '请上传开票信息二次校验图',
          trigger: 'blur'
        }
      ],
      pay_array: [
        {
          required: true,
          message: '请上传缴费订单',
          trigger: 'blur',
          type: 'array'
        }
      ],
      license: [
        {
          required: true,
          message: '请上传营业执照',  //请上传纳税人证明照
          trigger: 'blur'
        }
      ],
      // invoice_recipient: [
      //   {
      //     required: true,
      //     message: '请填写收件人昵称',
      //     trigger: 'blur'
      //   }
      // ],
      // telephone_recipient: [
      //   {
      //     required: true,
      //     message: '请填写收件人电话号码',
      //     trigger: 'blur'
      //   }
      // ],
      // address_recipient: [
      //   {
      //     required: true,
      //     message: '请填写邮递地址',
      //     trigger: 'blur'
      //   }
      // ]
    })
    const selectModify = (a) => {
      if (a === '增值税专用发票') {
        rules.value = {
          header: [
            {
              required: true,
              message: '请填写抬头',
              trigger: 'blur'
            }
          ],
          credit_code: [
            {
              required: true,
              message: '请填写税号',
              trigger: 'blur'
            }
          ],
          bank_name: [
            {
              required: true,
              message: '请填写开户名称',
              trigger: 'blur'
            }
          ],
          account_number: [
            {
              required: true,
              message: '请填写开户账号',
              trigger: 'blur'
            }
          ],
          registered_place: [
            {
              required: true,
              message: '请填写注册所地址',
              trigger: 'blur'
            }
          ],
          landline: [
            {
              required: true,
              message: '请填写电话号码',
              trigger: 'blur'
            }
          ],
          prove: [
            {
              required: true,
              message: '增值税一般纳税人证明',
              trigger: 'blur'
            }
          ],
          invoicing: [
            {
              required: true,
              message: '请上传开票信息',
              trigger: 'blur'
            }
          ],
          pay_array: [
            {
              required: true,
              message: '请上传缴费订单',
              trigger: 'blur',
              type: 'array'
            }
          ],
          license: [
            {
              required: true,
              message: '请上传营业执照',
              trigger: 'blur'
            }
          ],
          // invoice_recipient: [
          //   {
          //     required: true,
          //     message: '请填写收件人昵称',
          //     trigger: 'blur'
          //   }
          // ],
          // telephone_recipient: [
          //   {
          //     required: true,
          //     message: '请填写收件人电话号码',
          //     trigger: 'blur'
          //   }
          // ],
          // address_recipient: [
          //   {
          //     required: true,
          //     message: '请填写邮递地址',
          //     trigger: 'blur'
          //   }
          // ]
        }
      } else {
        rules.value = {
          header: [
            {
              required: true,
              message: '请填写抬头',
              trigger: 'blur'
            }
          ],
          credit_code: [
            {
              required: true,
              message: '请填写税号',
              trigger: 'blur'
            }
          ],
          invoicing: [
            {
              required: true,
              message: '请上传开票信息',
              trigger: 'blur'
            }
          ],
          pay_array: [
            {
              required: true,
              message: '请上传缴费订单',
              trigger: 'blur',
              type: 'array'
            }
          ],
          license: [
            {
              required: true,
              message: '请上传营业执照',
              trigger: 'blur'
            }
          ],
          // invoice_recipient: [
          //   {
          //     required: true,
          //     message: '请填写收件人昵称',
          //     trigger: 'blur'
          //   }
          // ],
          // telephone_recipient: [
          //   {
          //     required: true,
          //     message: '请填写收件人电话号码',
          //     trigger: 'blur'
          //   }
          // ],
          // address_recipient: [
          //   {
          //     required: true,
          //     message: '请填写邮递地址',
          //     trigger: 'blur'
          //   }
          // ]
        }
      }
    }

    const onSubmit = () => {
      formRef5.value
        .validate()
        .then(async () => {
          let id = window.sessionStorage.getItem('id')
          let user_id = window.sessionStorage.getItem('user-id')
          // console.log('submit!', toRaw(formState));
          let data = {
            invoice_id: null,
            order_id: id,
            user_id,
            ...toRaw(formState)
            // status:0
          }
          const invoice_id = window.sessionStorage.getItem('invoice_id')
          if (invoice_id) {
            data.invoice_id = invoice_id * 1
          }
          const res = await operationInvoice(data)
          // console.log(res);
          message.success(res.msg)
          window.sessionStorage.removeItem('kpxg')
          window.sessionStorage.removeItem('invoice_id')
          proxy.$root.$router.push({ name: 'invoiceRecord' })
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
    const init = async () => {
      let costs = window.sessionStorage.getItem('costs')
      let kpxg = window.sessionStorage.getItem('kpxg')
      if (kpxg) {
        const res = await findInvoice(kpxg)
        console.log(res.data)
        const obj = res.data
        formState.costs = costs
        formState.invoice_type = obj.invoice_type
        formState.header = obj.header
        formState.credit_code = obj.credit_code
        formState.bank_name = obj.bank_name
        formState.account_number = obj.account_number
        formState.registered_place = obj.registered_place
        formState.landline = obj.landline
        formState.pay_array = obj.pay_array
        formState.license = obj.license
        formState.prove = obj.prove
        formState.invoicing = obj.invoicing
        // formState.invoice_recipient = obj.invoice_recipient
        // formState.telephone_recipient = obj.telephone_recipient
        // formState.address_recipient = obj.address_recipient
        formState.email = obj.email
        imgObj.license = [{ url: obj.license, status: 'done' }]
        imgObj.prove = [{ url: obj.prove, status: 'done' }]
        imgObj.invoicing = [{ url: obj.invoicing, status: 'done' }]
        // imgObj.pay_array = [{ url: obj.pay_official_seal, status: 'done' }]
        // 缴费订单盖公章--回传数组
        // obj.pay_array
        let pay_arr = [];
        (obj.pay_array).forEach((item, i) => {
          pay_arr.push({
            uid: i,
            url: item,
            status: 'done'
          })
        });
        imgObj.pay_array = pay_arr

        window.sessionStorage.setItem('id', obj.order_id)
        window.sessionStorage.setItem('invoice_id', obj.invoice_id)
        notification['error']({
          message: '失败原因',
          description: obj.feedback,
          duration: null
        })
      }
      formState.costs = costs
    }
    onMounted(init)
    const itemArrFun = (str, arr) => {
      console.log(arr);
      if (str === 'pay_array') {
        if (!arr.length) {
          console.log('删除');
          formState[str] = []
          return
        }
        let a = [] 
        let b = [] 
        arr.forEach((item,i) => {
          a.push(item.url)
          b.push({ uid: i,url: item.url, status: 'done' })
        });
        console.log(a);
        formState[str] = a
        imgObj[str] = b
      }else{
        if (!arr.length) {
          formState[str] = ''
          return
        }
        formState[str] = arr[0].url
        // imgObj[str] = [{ url: arr[0].url, status: 'done' }]
      }
    }
    return {
      formRef5,
      labelCol: {
        span: 8
      },
      wrapperCol: {
        span: 8
      },
      formState,
      imgObj,
      onSubmit,
      itemArrFun,
      rules,
      selectModify
    }
  }
})
</script>

<style lang="less">
.label {
  font-weight: 600;
}
.label .ant-form-item-label > label {
  font-size: 14px;
  color: @primary-color;
}
.ant-form-item-label > label {
  font-size: 12px;
}
.colon .ant-form-item-label > label::after {
  content: "";
}
</style>
